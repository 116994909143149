<template>
  <div class="row text-start">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <br/><br/>
      <h3>Dane użytkownika</h3>
      <hr>

      <template v-if="editUserResult">
        <template v-if="editUserResult.isSuccess">
          <div class="alert alert-success" role="alert">
            {{editUserResult.message}}
          </div>
        </template>
        <template v-else>
          <div class="alert alert-danger" role="alert">
            {{editUserResult.message}}
          </div>
        </template>
      </template>
      <template v-if="addUserResult">
        <template v-if="addUserResult.isSuccess">
          <div class="alert alert-success" role="alert">
            {{addUserResult.message}}
          </div>
        </template>
        <template v-else>
          <div class="alert alert-danger" role="alert">
            {{addUserResult.message}}
          </div>
        </template>
      </template>
      <form @submit.prevent="submit()">

      <template v-if="userData.id">
        <div class="form-group row">
          <label for="id" class="col-sm-2 col-form-label">ID:</label>
          <div class="col-sm-10">
            <input class="form-control" id="id" v-model="userData.id" placeholder="edit me" readonly>
          </div>
        </div>
      </template>

      <div class="form-group row">
        <label for="email" class="col-sm-2 col-form-label">Email:</label>
        <div class="col-sm-10">
          <input class="form-control" id="email" v-model="userData.email" placeholder="edit me">
        </div>
      </div>

      <div class="form-group row">
        <label for="password" class="col-sm-2 col-form-label">Password:</label>
        <div class="col-sm-10">
          <input class="form-control" id="password" v-model="userData.password" placeholder="Leave it empty if you do not want to modify password">
        </div>
      </div>

      <div class="form-group row">
        <label for="terms_and_conditions_agreement" class="col-sm-2 col-form-label">Terms and conditions agreement:</label>
        <div class="col-sm-10">
          <input class="form-check" id="terms_and_conditions_agreement" type="checkbox" v-model="userData.termsAndConditionsAgreement" placeholder="edit me">
        </div>
      </div>

      <div class="form-group row">
        <label for="marketing-agreement" class="col-sm-2 col-form-label">Marketing agreement:</label>
        <div class="col-sm-10">
          <input class="form-check" id="agreement" type="checkbox" v-model="userData.marketingAgreement" placeholder="edit me">
        </div>
      </div>

      <p><b>Role:</b></p>
      <template v-for="role in userData.roles" v-bind:key="role">
        <p>- {{ role }}</p>
      </template>

      <button type="submit" class="btn btn-primary">
        <template v-if="userData.id">
          Zapisz zmiany
        </template>
        <template v-else>
          Dodaj użytkownika
        </template>
      </button>
      </form>

      <template v-if="userData.id">
        <hr>
        <button class="btn btn-primary" v-on:click="loginByUserSalt">Logged as user</button>
        <br/>
        Salt: {{ userData.loginSalt }}
      </template>

    </div>
    <div class="col-md-1"></div>
  </div>

  <template v-if="updateUserAccessResult">
    <template v-if="updateUserAccessResult.isSuccess">
      <div class="alert alert-success" role="alert">
        {{updateUserAccessResult.message}}
      </div>
    </template>
    <template v-else>
      <div class="alert alert-danger" role="alert">
        {{updateUserAccessResult.message}}
      </div>
    </template>
  </template>

  <template v-if="userData.id">
    <div class="row text-start">
      <div class="col-1"></div>
      <div class="col-10">
        <br/><br/>
        <h3>Dostęp</h3>
        <hr>
        <b>Current user access: {{userData.currentAccess}}</b>
        <br/>
        <br/>
        <h4>Add user access:</h4>
        <br/>
        <input style="float: left" type="radio" id="one" value="End Date" v-model="accessType" />
        <label style="float: left" for="one">End Date</label>
        <br/>
        <input style="float: left" type="radio" id="two" value="Number of days" v-model="accessType" />
        <label style="float: left" for="two">Number of days</label>
        <br/>
        <form @submit.prevent="updateUserAccess()">
          <div class="form-group row" v-if="accessType === 'End Date'">
            <label for="endDate" class="col-sm-2 col-form-label">End date:</label>
            <div class="col-sm-10">
              <input class="form-control" id="endDate" v-model="access.endDate" placeholder="Please, provide date in the following format: 2023-03-08">
            </div>
          </div>
          <div class="form-group row" v-if="accessType === 'Number of days'">
            <label for="daysNumber" class="col-sm-2 col-form-label">Number of access days:</label>
            <div class="col-sm-10">
              <input class="form-control" id="daysNumber" v-model="access.daysNumber" placeholder="Please, provide number of access days">
            </div>
          </div>
          <button type="submit" class="btn btn-primary">Zapisz</button>
        </form>
      </div>
      <div class="col-1"></div>
    </div>
  </template>

  <template v-if="userData.id">
  <br/>
  <br/>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h3 class="text-start">Kody wykorzystane przez użytkownika</h3>
      <table id="codes-table" class="list-table">
        <tr>
          <th>ID</th>
          <th>Used At</th>
          <th>Code</th>
          <th> Sales Package Name</th>
        </tr>
        <template v-for="salesPackageCode in salesPackageCodesList" v-bind:key="salesPackageCode.id">
          <tr>
            <td>{{ salesPackageCode.code.id }}</td>
            <td>{{ salesPackageCode.usedAt }}</td>
            <td>{{ salesPackageCode.code.code }}</td>
            <td>
              <span class="fm-link pointer" v-on:click="redirectToSalesPackageByName(salesPackageCode.salesPackage.name)">
                {{ salesPackageCode.salesPackage.name }}
              </span>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div class="col-md-1"></div>
  </div>
  </template>
</template>

<script>
import UserService from '../../../services/userService';
import { SalesPackageService } from '../../../services/admin/SalesPackage/salesPackageService';
import { RegisterService } from '../../../services/registerService';
import { CookieService } from '../../../services/cookieService';
import AccessService from '../../../services/accessService';

export default {
  name: 'User',
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.userId = toParams.id ?? null;
        if (this.userId) {
          this.getAllData();
        } else {
          this.resetUserData();
        }
      }
    );
  },
  mounted() {
    const routeParams = this.$route.params;
    this.userId = routeParams.id ?? null;
    if (this.userId) {
      this.getAllData();
    } else {
      this.resetUserData();
    }
  },
  data() {
    return {
      userId: null,
      userData: {
        id: null,
        username: null,
        email: null,
        password: null,
        termsAndConditionsAgreement: null,
        marketingAgreement: null,
        loginSalt: null,
        currentAccess: null
      },
      access: {
        daysNumber: null,
        endDate: null
      },
      accessType: null,
      salesPackageCodesList: [],
      addUserResult: null,
      editUserResult: null,
      updateUserAccessResult: null
    };
  },
  methods: {
    async getAllData() {
      if (this.userId) {
        await this.displayUserData();
        await this.getCodesDataUsedBySpecificUser();
        await this.getUserAccess();
      }
    },
    async displayUserData() {
      const response = await UserService.getUserByUserId(this.userId);
      const user = response.data.users.items[0];

      this.userData.id = user.id;
      this.userData.username = user.username;
      this.userData.email = user.email;
      this.userData.termsAndConditionsAgreement = user.termsAndConditionsAgreement;
      this.userData.marketingAgreement = user.marketingAgreement;
      this.userData.loginSalt = user.loginSalt;
      this.userData.roles = user.roles;
    },
    async getCodesDataUsedBySpecificUser() {
      const response = await SalesPackageService.getCodesUsedBySpecificUser(this.userData.id);
      const salesPackageCodes = response.data.salesPackageCodes;
      // this.existingCodes = salesPackageCodes.items;

      if (salesPackageCodes.items) {
        for (const salesPackageCode of salesPackageCodes.items) {
          this.salesPackageCodesList.push(salesPackageCode);
        }
      }
    },
    async redirectToSalesPackageByName(salesPackageName) {
      const response = await SalesPackageService.getSalesPackageDataByName(salesPackageName);
      const salesPackage = response.data.salesPackage;
      const salesPackageId = salesPackage.items[0].id;

      await this.$router.push({
        name: 'SalesPackageDetails',
        params: { id: salesPackageId }
      });
    },
    async loginAsUserBySalt(salt) {
      await UserService.loginAsUserBySalt(salt);
    },
    async submit() {
      if (this.userData.id) {
        await this.saveChanges();
      } else {
        await this.addUser();
      }
    },
    async updateUserAccess() {
      const response = await AccessService.updateUserAccess(this.userData.id, 1, this.access.daysNumber, this.access.endDate);
      this.updateUserAccessResult = response.data.access;
      await this.getUserAccess();
    },
    async getUserAccess() {
      const response = await AccessService.getAccessData(this.userData.id);

      if (response.data.access.items) {
        this.userData.currentAccess = response.data.access.items[0].expiredAt;
      }
    },
    async saveChanges() {
      const response = await UserService.editUser(
        this.userData.id,
        this.userData.email,
        this.userData.username,
        this.userData.termsAndConditionsAgreement,
        this.userData.marketingAgreement,
        this.userData.password
      );

      this.editUserResult = response.data;

      if (this.editUserResult.isSuccess) {
        this.userData.password = null;
        await this.$router.push('/admin/user/' + this.userData.id);
      }
    },
    async addUser() {
      const response = await RegisterService.register(
        this.userData.email,
        this.userData.password,
        this.userData.termsAndConditionsAgreement,
        this.userData.marketingAgreement
      );

      this.addUserResult = response.data;

      if (this.addUserResult.isSuccess) {
        this.userData.password = null;
        await this.$router.push('/admin/user/' + this.addUserResult.user.id);
      }
    },
    resetUserData() {
      this.userData.id = null;
      this.userData.username = null;
      this.userData.email = null;
      this.userData.password = null;
      this.userData.termsAndConditionsAgreement = null;
      this.userData.marketingAgreement = null;
      this.userData.roles = null;
    },
    async loginByUserSalt() {
      const response = await UserService.loginAsUserBySalt(this.userData.loginSalt);
      const token = response.data.token;

      if (token) {
        const realToken = CookieService.getCookie('token');
        CookieService.setCookie('realToken', realToken);
        CookieService.setCookie('token', token);
        await this.$router.push('/funbook');
      }
    }
  }
};
</script>

<style scoped>
.list-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.list-table td, .list-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.list-table tr:nth-child(even){background-color: #f2f2f2;}

.list-table tr:hover {background-color: #ddd;}

.list-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.fm-link {
  text-decoration: underline;
  color: blue;
}
.pointer {
  cursor: pointer;
}
</style>
